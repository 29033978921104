// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-commercial-cleaning-js": () => import("./../../../src/pages/commercial-cleaning.js" /* webpackChunkName: "component---src-pages-commercial-cleaning-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-home-cleaning-js": () => import("./../../../src/pages/home-cleaning.js" /* webpackChunkName: "component---src-pages-home-cleaning-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-quotes-commercial-cleaning-js": () => import("./../../../src/pages/quotes/commercial-cleaning.js" /* webpackChunkName: "component---src-pages-quotes-commercial-cleaning-js" */),
  "component---src-pages-quotes-home-cleaning-js": () => import("./../../../src/pages/quotes/home-cleaning.js" /* webpackChunkName: "component---src-pages-quotes-home-cleaning-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-whats-included-js": () => import("./../../../src/pages/whats-included.js" /* webpackChunkName: "component---src-pages-whats-included-js" */)
}

